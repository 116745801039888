





























































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import RemoteStart from '../../components/chargepoint/RemoteStart'
import Reset from '../../components/chargepoint/Reset'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    RemoteStart,
    Reset
  },
  filters: {
    formatEuro(val: any) {
      let valRt: any = 0
      if (val >= 0) {
        valRt = (val / 1)
          .toFixed(4)
          .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')
      }
      return valRt + ' €'
    },
    toBlinkLabel (val: string) {
      return val === 'BlueCorner' ? 'Blink' : val
    }
  }
})
export default class ChargepointCard extends Vue {
  @Prop(Boolean) readonly loading: any
  @Prop(Object) readonly selectedMarker: any
  @Prop(Object) readonly containerstyle: any

  priceDialog = false

  colors = {
    Offline: 'grey',
    Cperror: 'grey',
    Available: 'green',
    Occupied: 'blue',
    Error: 'red',
    Unavailable: 'grey',
    Unknown: 'grey'
  }

  close() {
    this.$emit('close')
  }
}
