<template>
  <div>
    <v-dialog v-model="legend" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="title">{{ t('$vuetify.map.legend') }}</span>
          <v-spacer></v-spacer>
          <v-btn icon small @click="legend = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-img
                  width="42"
                  lazy-src="/markers/OccupiedAC.png"
                  src="/markers/OccupiedAC.png"
                ></v-img>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="body-1">{{
                  t('$vuetify.map.occupiedParking')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-img
                  width="42"
                  lazy-src="/markers/AvailableAC.png"
                  src="/markers/AvailableAC.png"
                ></v-img>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="body-1">{{
                  t('$vuetify.map.Available')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-img
                  width="42"
                  lazy-src="/markers/Home.png"
                  src="/markers/Home.png"
                ></v-img>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="body-1">{{
                  t('$vuetify.map.myChargers')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-img
                  width="42"
                  lazy-src="/markers/roaming.png"
                  src="/markers/roaming.png"
                ></v-img>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="body-1">{{
                  t('$vuetify.map.otherNetworks')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-flex md12 xs12>
      <v-layout row wrap>
        <v-flex :class="isDetail && windowSize.x <= 400 ? 'd-none' : ''">
          <GmapMap
            class="scroll map-container"
            v-resize="calcHeight"
            :center="center"
            :zoom="zoom"
            :options="{
              zoomControl: true,
              minZoom: 6,
              maxZoom: 18,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false,
              gestureHandling: 'greedy'
            }"
            ref="mapRef"
            @bounds_changed="changeMap()"
          >
          </GmapMap>
          <div
            ref="legendBtn"
            class="legenda pa-2 mt-4 mb-5"
          >
            <v-btn small @click="legend = true" class="mr-5">{{
              t('$vuetify.map.legend')
            }}</v-btn>
          </div>
          <div
            ref="filterBtn"
            class="legenda pa-2 mt-4 mb-5"
          >
            <v-btn small @click="filtersMenu = true">{{
              t('$vuetify.map.filters')
            }}</v-btn>
          </div>
          <div ref="searchFilter" class="legenda pa-2 mt-4">
            <gmap-autocomplete
              id="autocomplet"
              append-icon="search"
              :placeholder="t('$vuetify.map.zoomLocation')"
              class="ml-5 location pa-2"
              @place_changed="setPlace"
            >
            </gmap-autocomplete>
          </div>
        </v-flex>
        <v-flex md3 xs12 v-if="isDetail" style="height: 100%">
          <ChargeLocationDetail
            :containerstyle="containerstyle"
            @close="closeDetail"
            :selectedMarker="selectedMarker"
            :loading="loading"
          />
        </v-flex>
        <v-flex md3 xs12 v-if="isList">
          <ChargeLocationListDetail
            :containerstyle="containerstyle"
            @close="closeList"
            :selectedMarker="listMarkers"
            :loading="loading"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-navigation-drawer right temporary v-model="filtersMenu" fixed>
      <v-toolbar flat>
        <v-toolbar-title class="pl-3"
          ><span>Blink</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-icon @click="filtersMenu = false">mdi-chevron-right</v-icon>
      </v-toolbar>
      <v-list class="pt-0" dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ t('$vuetify.map.filterChargerType') }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-flex v-for="(setting, index) in settings" :key="setting.label">
          <v-list-item
            :disabled="setting.labelVal === 'Blink Network'"
            @click="updateSettings(index)"
          >
            <v-list-item-action>
              <v-icon
                v-if="setting.icon"
                :class="setting.isActive ? 'blue--text' : 'grey--text'"
              >
                {{ setting.icon }}</v-icon
              >
              <img v-else :src="setting.img" width="22" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                :class="setting.isActive ? 'blue--text' : 'grey--text'"
              >
                {{ t(setting.label) }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="setting.labelVal === 'Germany'">
            <v-list-item-content>
              <v-list-item-title>
                {{ t('$vuetify.map.filterChargerType') }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="setting.labelVal === 'Germany'"></v-divider>
          <v-list-item v-if="setting.labelVal === 'Other Networks'">
            <v-list-item-content>
              <v-list-item-title>
                {{ t('$vuetify.map.otherFilters') }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="setting.labelVal === 'Other Networks'"></v-divider>
        </v-flex>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable */
/* global google:true */

import Vue from 'vue'
import MapApi from '../../services/MapApi'
import RemoteStart from '../../components/chargepoint/RemoteStart'
import Reset from '../../components/chargepoint/Reset'
import LastTenSessions from '../../components/chargepoint/LastTenSession.vue'
import ChargeLocationDetail from './ChargeLocationDetail.vue'
import ChargeLocationListDetail from './ChargeLocationListDetail.vue'

export default {
  components: {
    RemoteStart,
    Reset,
    LastTenSessions,
    ChargeLocationDetail,
    ChargeLocationListDetail
  },
  data: () => ({
    isEditLocation: false,
    autoResize: true,
    isDetail: false,
    isActiveSession: false,
    zoomLocation: '',
    zIndex: 0,
    overlay: true,
    payload: {
      id: 0,
      street: '',
      housenr: '',
      city: '',
      postalcode: '',
      country: '',
      description: '',
      name: '',
      lng: '',
      lat: '',
      loading: false
    },
    colors: {
      Offline: 'grey',
      Cperror: 'grey',
      Available: 'green',
      Occupied: 'blue',
      Error: 'grey',
      Unavailable: 'grey',
      Unknown: 'grey'
    },
    windowSize: {
      x: 0,
      y: 0
    },
    containerstyleCard: {},
    legend: false,
    containerstyle: {
      featureType: 'poi',
      width: '100%',
      height: '100%',
      stylers: [{ visibility: 'off' }]
    },
    center: { lat: 51, lng: 4.3 },
    zoom: 7,
    curZoom: 3,
    map: null,
    styles: {
      default: null,
      hide: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }]
        }
      ]
    },
    markers: [],
    cluster: null,
    loading: false,
    selection: 1,
    selectedMarker: {},
    listMarkers: [],
    mapTab: [],
    mapTabAll: [],
    noErLat: '',
    noErLng: '',
    soWeLat: '',
    soWeLng: '',
    isRoaming: 0,
    isMychargers: 0,
    isBCChargers: 1,
    isAC: 0,
    isBC: 0,
    configLg: {},
    settings: [
      {
        label: '$vuetify.map.bcNetwork',
        isActive: true,
        labelVal: 'Blue Corner Network',
        icon: '$vuetify.icons.type2'
      },
      {
        label: '$vuetify.map.myChargersFilter',
        isActive: false,
        labelVal: 'My chargers',
        icon: '$vuetify.icons.type2'
      },
      {
        label: '$vuetify.map.otherNetworkFilter',
        isActive: false,
        labelVal: 'Other Networks',
        icon: '$vuetify.icons.type2'
      },
      {
        label: '$vuetify.map.showFacilities',
        isActive: false,
        labelVal: 'Show Facilities',
        icon: 'mdi-domain'
      }
    ],
    isList: false,
    latestPosLng: 0,
    latestPosLat: 0,
    timer: null,
    filtersMenu: false,
    clusterStyles: [
      {
        textColor: 'green',
        url: '/markers/circle-outlinev2.png',
        height: 32,
        width: 32
      },
      {
        textColor: 'green',
        url: '/markers/circle-outlinev2.png',
        height: 32,
        width: 32
      },
      {
        textColor: 'green',
        url: '/markers/circle-outlinev2.png',
        height: 32,
        width: 32
      }
    ],
    isStartEdit: false
  }),
  mounted() {
    this.loadMap()
    this.onResize()
  },
  methods: {
    async updateLocation() {
      const dt = await new MapApi().createUpdate(this.payload)
      this.isDetail = false
    },
    cancelEdit() {
      this.isEditLocation = false
      var markers = this.cluster.getMarkers()
      var vm = this
      for (let index = 0; index < markers.length; index++) {
        let element = markers[index]
        if (
          element.getPosition().lat() === parseFloat(this.payload.lat) &&
          element.getPosition().lng() === parseFloat(this.payload.lng)
        ) {
          markers[index].setDraggable(false)
          const myLatlng = new google.maps.LatLng(
            this.latestPosLat,
            this.latestPosLng
          )
          this.payload.lat = this.latestPosLat
          this.payload.lng = this.latestPosLng
          markers[index].setPosition(myLatlng)
          this.isEditLocation = false
        }
      }
    },

    closeDetail() {
      this.isDetail = false
    },

    closeList() {
      this.isList = false
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    setPlace(place) {
      if (!place) return
      if (place.geometry !== undefined) {
        this.map.setCenter(place.geometry.location)
        this.map.setZoom(15)
      }
    },
    updateSettings(index) {
      this.filtersMenu = false
      var vm = this
      if (this.map !== null) {
        this.settings[index].isActive = !this.settings[index].isActive
        if (this.settings[index].labelVal === 'Blink Network') {
          this.clearMarkersBis()
        }
        if (this.settings[index].labelVal === '< 50kW') {
          this.isAC = this.settings[index].isActive ? 1 : 0
          this.clearMarkersBis()
          this.getManaged()
        }
        if (this.settings[index].labelVal === '< 50kW') {
          this.isAC = this.settings[index].isActive ? 1 : 0
          this.clearMarkersBis()
          this.getManaged()
        }
        if (this.settings[index].labelVal === 'My chargers') {
          this.isMychargers = this.settings[index].isActive ? 1 : 0
          this.clearMarkersBis()
          this.getManaged()
        }
        if (this.settings[index].labelVal === 'Other Networks') {
          this.isRoaming = this.settings[index].isActive ? 1 : 0
          this.clearMarkersBis()
          this.getManaged()
        }
        if (this.settings[index].labelVal === 'Show facilities') {
          if (this.settings[index].isActive) {
            this.map.setOptions({ styles: this.styles['default'] })
          } else {
            this.map.setOptions({ styles: this.styles['hide'] })
          }
        }
      }
    },

    loadControls() {
      const controlDiv = document.createElement('div')
      controlDiv.classList.add('control-div')

      const firstChild = document.createElement('button')
      firstChild.classList.add('control-button')
      firstChild.title = 'Your Location'
      controlDiv.appendChild(firstChild)

      const secondChild = document.createElement('div')
      secondChild.classList.add('control-image')
      firstChild.appendChild(secondChild)

      window.google.maps.event.addListener(
        this.$refs.mapRef.$mapObject,
        'center_changed',
        () => {
          secondChild.style.backgroundPosition = '0 0'
        }
      )

      firstChild.addEventListener('click', this.getMyLocation.bind(this))

      this.$refs.mapRef.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(controlDiv)

      const breakpoint = this.$vuetify.breakpoint.mdAndUp
      const bottomCenterControls =
        this.$refs.mapRef.$mapObject.controls[
          window.google.maps.ControlPosition.BOTTOM_CENTER
        ]
      const topRightControls =
        this.$refs.mapRef.$mapObject.controls[
          window.google.maps.ControlPosition.TOP_RIGHT
        ]
      const leftTopControls =
        this.$refs.mapRef.$mapObject.controls[
          window.google.maps.ControlPosition.LEFT_TOP
        ]

      if (!breakpoint) {
        bottomCenterControls.push(this.$refs.legendBtn)
        bottomCenterControls.push(this.$refs.filterBtn)
      } else {
        topRightControls.push(this.$refs.legendBtn)
        topRightControls.push(this.$refs.filterBtn)
      }

      topRightControls.push(this.$refs.activeSessionBtn)
      bottomCenterControls.push(this.$refs.activeMobileSessionBtn)
      leftTopControls.push(
        this.$refs.searchFilter,
        this.$refs.activeMobileSessies,
        this.$refs.activeSessies
      )
    },

    loadMap() {
      const elm = this.$refs.mapRef
      const vm = this
      elm.$mapPromise.then((map) => {
        vm.map = map
        vm.map.setOptions({
          streetViewControl: false,
          styles: this.styles.hide
        })
        vm.loadControls()
        const mcOptions = {
          gridSize: 50,
          maxZoom: 14,
          styles: this.clusterStyles
        }
      })
    },

    calcHeightCard() {
      const element = this.$refs.mapRef.$el
      const parentContainer = element.parentElement
      const parentHeight = element.clientHeight
      const yPosition =
        parentContainer.offsetTop -
        parentContainer.scrollTop +
        parentContainer.clientTop

      this.containerstyle = {
        featureType: 'poi',
        width: '100%',
        height: element.clientHeight + 'px',
        stylers: [{ visibility: 'off' }]
      }
    },

    getMyLocation() {
      var infoWindow = new google.maps.InfoWindow()
      var vm = this
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            infoWindow.setPosition(pos)
            infoWindow.setContent('Location found.')
            infoWindow.open(vm.map)
            vm.map.setCenter(pos)
          },
          function () {
            vm.handleLocationError(true, infoWindow, vm.map.getCenter())
          }
        )
      } else {
        // Browser doesn't support Geolocation
        vm.handleLocationError(false, infoWindow, vm.map.getCenter())
      }
    },

    async getManaged() {
      const maxLat = this.soWeLat > this.noErLat ? this.soWeLat : this.noErLat
      const maxLng = this.soWeLng > this.noErLng ? this.soWeLng : this.noErLng
      const minLat = this.soWeLat < this.noErLat ? this.soWeLat : this.noErLat
      const minLng = this.soWeLng < this.noErLng ? this.soWeLng : this.noErLng
      const curZoom = this.map.getZoom()
      const dt = await new MapApi().list(
        minLng,
        minLat,
        maxLng,
        maxLat,
        this.isRoaming,
        this.isMychargers,
        this.isBCChargers,
        curZoom
      )
      this.mapTab = dt.result.data
      this.mapTabAll = dt
      this.clearMarkersBis()
      if (this.map.getZoom() < 15) {
        this.overlay = true
        this.refreshMap()
      } else {
        const tabMultiple = []
        for (let index = 0; index < this.mapTab.length; index++) {
          const element = this.mapTab[index]
          let isfound = false
          let pos = 0
          for (let j = 0; j < tabMultiple.length && !isfound; j++) {
            const subElm = tabMultiple[j]
            if (
              element.latitude === subElm.latitude &&
              element.longitude === subElm.longitude
            ) {
              isfound = true
              pos = j
            }
          }
          if (isfound) {
            tabMultiple[pos].size += 1
            tabMultiple[pos].tab.push(element)
          } else {
            const elmNew = {
              longitude: element.longitude,
              latitude: element.latitude,
              size: 1,
              tab: []
            }
            elmNew.tab.push(element)
            tabMultiple.push(elmNew)
          }
        }
        this.clearMarkersBis()
        const tmpArray = []
        for (let index = 0; index < tabMultiple.length; index++) {
          const elm = tabMultiple[index]
          var myLatlng = new google.maps.LatLng(elm.latitude, elm.longitude)
          var marker = new google.maps.Marker({
            position: myLatlng
          })

          if (elm.size > 1) {
            const size = '' + elm.size
            marker.setLabel(size)
            marker.setIcon({
              url: '/markers/circle-outlinev2.png'
            })
            marker.size = size
            if (elm) {
              var vm = this
              google.maps.event.addListener(marker, 'click', async function () {
                vm.isList = true
                vm.isDetail = false
                vm.listMarkers = elm.tab
                vm.onResize()
                vm.loading = false
              })
            }
          } else {
            elm = elm.tab[0]
            if (elm.roaming === 0 && elm.mycharger === 0) {
              if (elm.state === 'Available') {
                marker.setIcon({
                  url: '/markers/Available' + elm.mode + '.png'
                })
              } else {
                marker.setIcon({
                  url: '/markers/Occupied' + elm.mode + '.png'
                })
              }
            } else {
              if (elm.mycharger === 1) {
                marker.setIcon({
                  url: '/markers/Home.png'
                })
              } else {
                marker.setIcon({
                  url: '/markers/roaming.png'
                })
              }
            }
            if (elm) {
              var vm = this
              if (!elm.size) {
                google.maps.event.addListener(
                  marker,
                  'click',
                  async function () {
                    vm.isDetail = true
                    vm.isList = false
                    vm.onResize()
                    vm.loading = true
                    const dt = await new MapApi().detail(
                      elm.id,
                      elm.latitude,
                      elm.longitude
                    )
                    vm.loading = false
                    vm.selectedMarker = dt.result.data
                    vm.selectedMarker.id = elm.id
                    vm.payload.id = dt.result.data.location.id
                    vm.payload.lat = elm.latitude
                    vm.payload.lng = elm.longitude
                    vm.payload.street = dt.result.data.location.street
                    vm.payload.housenr = dt.result.data.location.housenumber
                    vm.payload.city = dt.result.data.location.city
                    vm.payload.postalcode = dt.result.data.location.postalcode
                    vm.payload.country = dt.result.data.location.country
                    vm.payload.description = dt.result.data.location.description
                    vm.payload.name = dt.result.data.location.name
                  }
                )
              }
            }
          }
          tmpArray.push(marker)
        }
        for (let index = 0; index < tmpArray.length; index++) {
          const marker = tmpArray[index]
          marker.setClickable(true)
          this.addMarkerWithTimeout(marker, 0)
        }
        this.overlay = false
      }
    },
    clearMarkersBis() {
      if (this.map.getZoom() <= 15) {
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null)
          this.markers[i] = null
          this.markers.splice(i, 1)
          i = i - 1
        }
      }
    },
    clearMarkers() {
      for (let i = 0; i < this.markers.length && this.mapTab.length > 0; i++) {
        const elm = this.markers[i]
        if (!this.containsMarkerBis(elm)) {
          this.markers[i].setMap(null)
          this.markers.splice(i, 1)
          i = i - 1
        }
      }
      if (this.mapTab.length === 0) {
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null)
          this.markers[i] = null
          this.markers.splice(i, 1)
          i = i - 1
        }
      }
    },
    containsMarkerBis(marker) {
      let isFound = false
      for (let index = 0; index < this.mapTab.length && !isFound; index++) {
        const element = this.mapTab[index]
        if (
          marker.getPosition().lat() === parseFloat(element.latitude) &&
          marker.getPosition().lng() === parseFloat(element.longitude)
        ) {
          isFound = true
        }
      }
      return isFound
    },
    containsMarker(marker) {
      let isFound = {
        state: false,
        pos: 0
      }
      for (
        let index = 0;
        index < this.markers.length && !isFound.state;
        index++
      ) {
        const element = this.markers[index]
        if (
          element.getPosition().lat() === parseFloat(marker.latitude) &&
          element.getPosition().lng() === parseFloat(marker.longitude)
        ) {
          isFound.state = true
          isFound.pos = index
        }
      }
      return isFound
    },
    refreshMap() {
      const tmpArray = []
      for (let index = 0; index < this.mapTab.length; index++) {
        const elm = this.mapTab[index]
        let rslt = this.containsMarker(elm)
        if (!rslt.state) {
          var myLatlng = new google.maps.LatLng(elm.latitude, elm.longitude)
          var marker = new google.maps.Marker({
            position: myLatlng
          })
          if (elm.roaming === 0 && elm.mycharger === 0) {
            if (elm.state === 'Available') {
              marker.setIcon({
                url: '/markers/Available' + elm.mode + '.png'
              })
            } else {
              marker.setIcon({
                url: '/markers/Occupied' + elm.mode + '.png'
              })
            }
          } else {
            if (elm.mycharger === 1) {
              marker.setIcon({
                url: '/markers/Home.png'
              })
            } else {
              marker.setIcon({
                url: '/markers/roaming.png'
              })
            }
          }
          if (elm.size && this.map.getZoom() < 18) {
            const size = '' + elm.size
            marker.setLabel(size)
            marker.setIcon({
              url: '/markers/circle-outlinev2.png'
            })
            marker.size = size
          }

          if (elm.size > 1 && this.map.getZoom() >= 18) {
            marker.special = true
          }
          if (elm) {
            var vm = this
            if (!elm.size) {
              google.maps.event.addListener(marker, 'click', async function () {
                vm.isDetail = true
                vm.isList = false
                vm.onResize()
                vm.loading = true
                const dt = await new MapApi().detail(
                  elm.id,
                  elm.latitude,
                  elm.longitude
                )
                vm.loading = false
                vm.selectedMarker = dt.result.data
                vm.selectedMarker.id = elm.id
                vm.payload.id = dt.result.data.location.id
                vm.payload.lat = elm.latitude
                vm.payload.lng = elm.longitude
                vm.payload.street = dt.result.data.location.street
                vm.payload.housenr = dt.result.data.location.housenumber
                vm.payload.city = dt.result.data.location.city
                vm.payload.postalcode = dt.result.data.location.postalcode
                vm.payload.country = dt.result.data.location.country
                vm.payload.description = dt.result.data.location.description
                vm.payload.name = dt.result.data.location.name
              })
            }
          }
          tmpArray.push(marker)
        }
      }
      for (let index = 0; index < tmpArray.length; index++) {
        const marker = tmpArray[index]
        marker.setClickable(false)
        this.addMarkerWithTimeout(marker, 0)
      }
      this.overlay = false
    },

    addMarkerWithTimeout(marker, timeout) {
      window.setTimeout(() => {
        marker.setMap(this.map)
        this.markers.push(marker)
      }, timeout)
    },

    calcHeight() {
      const element = this.$refs.mapRef.$el
      const yPosition =
        element.offsetTop - element.scrollTop + element.clientTop
      let height = window.innerHeight - yPosition

      if (height < 450) {
        height = 800
      }
      this.containerstyle = {
        featureType: 'poi',
        width: '100%',
        height: element.clientHeight + 'px',
        stylers: [{ visibility: 'off' }]
      }
    },
    changeMap() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => this.updateMap(), 500)
    },
    changeMarkerLocation() {
      var markers = this.cluster.getMarkers()
      var vm = this
      for (let index = 0; index < markers.length; index++) {
        let element = markers[index]
        if (
          element.getPosition().lat() === parseFloat(this.payload.lat) &&
          element.getPosition().lng() === parseFloat(this.payload.lng)
        ) {
          markers[index].setDraggable(true)
          this.isEditLocation = false
          google.maps.event.addListener(markers[index], 'dragend', function () {
            vm.updatePosition(markers[index].getPosition())
          })
          if (this.isStartEdit) {
            this.latestPosLng = markers[index].getPosition().lng()
            this.latestPosLat = markers[index].getPosition().lat()
            this.isStartEdit = false
          }
        }
      }
    },
    updatePosition(lngLat) {
      this.payload.lng = lngLat.lng()
      this.payload.lat = lngLat.lat()
      this.isEditLocation = true
    },
    updateMap() {
      const configLg = this.map.getBounds()
      this.noErLat = configLg.getNorthEast().lat()
      this.noErLng = configLg.getNorthEast().lng()
      this.soWeLat = configLg.getSouthWest().lat()
      this.soWeLng = configLg.getSouthWest().lng()
      this.getManaged()
    }
  }
}
</script>

<style>
.monitoring.card {
  height: 50%;
}
.legenda .pa-2 {
  border-radius: 2px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  color: black;
  margin: 5px;
}
.location {
  padding: 4px 0 2px;
  background: white;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: none;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  height: 28px;
  width: 100%;
  min-width: 300px;
  box-shadow: 0px 3px 1px -2px rgba(19, 17, 17, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
.map-container {
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
}
.map-card {
  text-align: justify;
  line-height: 19px;
  overflow: hidden;
}
</style>
