


























































import ChargeSessionApi from '../../services/ChargeSessionApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import CdrDuration from '../../components/sessions/CdrDuration.vue'

@Component({
  components: {
    CdrDuration
  },
  filters: {
    formatEuro (val: any) {
      let valRt: any = 0
      if (val >= 0) {
        valRt = (val / 1).toFixed(2).replace('.', ',')
      }
      return valRt + ' €'
    }
  }
})
export default class LastTenSessions extends Vue {
  @Prop(String) readonly cp: any
  pastSessions: Array<any> = []
  loading = false

  mounted () {
    if (this.cp) {
      this.fetchPastSessions()
    }
  }

  async fetchPastSessions () {
    this.loading = true
    try {
      const dt = await new ChargeSessionApi().getTenLastSessions(this.cp)
      this.loading = false
      this.pastSessions = dt
    } catch (err) {
      this.loading = false
    }
  }

  @Watch('cp')
  doReload () {
    this.fetchPastSessions()
  }
}
