






























































import Vue from 'vue'
import Component from 'vue-class-component'
import RemoteStart from '../../components/chargepoint/RemoteStart'
import Reset from '../../components/chargepoint/Reset'
import { Prop } from 'vue-property-decorator'
import ChargeLocationDetail from './ChargeLocationDetail.vue'
import MapApi from '../../services/MapApi'

@Component({
  components: {
    RemoteStart,
    Reset,
    ChargeLocationDetail
  },
  filters: {
    toBlinkLabel (val: string) {
      return val === 'BlueCorner' ? 'Blink' : val
    }
  }
})
export default class ChargepointCard extends Vue {
  @Prop(Boolean) readonly loading: any
  @Prop(Array) readonly selectedMarker: any
  @Prop(Object) readonly containerstyle: any

  priceDialog = false
  isDetail = false
  currentSelectedMarker: any = {}
  colors = {
    Offline: 'grey',
    Cperror: 'grey',
    Available: 'green',
    Occupied: 'blue',
    Error: 'red',
    Unavailable: 'grey',
    Unknown: 'grey'
  }

  close() {
    this.isDetail = false
  }

  closeList() {
    this.$emit('close')
    this.isDetail = false
  }

  async loadDataMarker(item: any) {
    this.isDetail = true
    const dt = (await new MapApi().detail(
      item.id,
      item.latitude,
      item.longitude
    )) as any
    this.currentSelectedMarker = dt.result.data
    this.currentSelectedMarker.id = item.id
  }
}
