








import moment from 'moment'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class ChargeSessions extends Vue {
  @Prop(String) readonly start?: string
  @Prop(String) readonly end?: string
  @Prop({ type: String, default: 'center' }) readonly position?: string
  @Prop(String) readonly classes?: string

  get _startDay () {
    return moment(this.start).format('ddd DD MMM YYYY')
  }

  get _endDay () {
    return moment(this.end).format('ddd DD MMM YYYY')
  }

  get _startDayShort () {
    return moment(this.start).format('ddd DD MMM YY')
  }

  get _endDayShort () {
    return moment(this.end).format('ddd DD MMM YYYY')
  }

  get _sameDay () {
    if (this._startDay === this._endDay) {
      return true
    }
    return false
  }

  get _startTime () {
    return moment(this.start).format('HH:mm')
  }

  get _endTime () {
    return moment(this.end).format('HH:mm')
  }
}
