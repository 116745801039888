import { AxiosRequestConfig } from 'axios'
import BaseApi from './BaseApi'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import PaginationInterface from '@/typings/PaginationInterface'
import { ChargeSessionFilterInterface } from '@/typings/ChargeSession'

export default class ChargeSessionApi extends BaseApi {
  constructor(type = 0) {
    super('Session', type)
  }

  async filtered({
    filterProps,
    filter,
    relationId,
    relationContactId
  }: {
    filterProps: PaginationInterface
    filter: ChargeSessionFilterInterface
    relationId?: string
    relationContactId?: string
  }) {
    const opts: AxiosRequestConfig = {
      url: `/${process.env.VUE_APP_API_VERSION}/session/BCO/sessionlist/filtered`,
      params: {
        pagesize: filterProps.pagesize,
        pagenumber: filterProps.pagenumber,
        caching: 0,
        filter,
        relationId,
        relationContactId
      }
    }
    if (!filterProps.type) {
      opts.params.type = 0
    } else {
      opts.params.type = filterProps.type
    }

    const dt = (await this.getApi(opts, true)) as DefaultResponseInterface
    if (dt.data && dt.result) {
      if (dt.result.status === 'success') {
        return { data: dt.data.records, pagination: dt.data.page }
      }
    }
  }

  async details(sessionId: number | string) {
    const options = {
      url: `/${process.env.VUE_APP_API_VERSION}/session/BCO/details/${sessionId}`
    }
    const dt = (await this.getApi(options)) as DefaultResponseInterface
    return dt.data
  }

  async getTenLastSessions(ChargePointId: string | number) {
    const dt = (await this.getApi(
      `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/session/BCO/lasttenbycp/${ChargePointId}`
    )) as DefaultResponseInterface
    return dt.data
  }
}
